import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Paper, CircularProgress, Card, CardContent } from "@mui/material";
import { Line, Bar, Doughnut, Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch users data to get feedback information
    Promise.all([
      fetch("/api/analytics/dashboard"),
      fetch("/api/users?limit=1000")  // Fetch all users to get feedback data
    ])
      .then(([analyticsRes, usersRes]) => 
        Promise.all([analyticsRes.json(), usersRes.json()])
      )
      .then(([analyticsData, usersData]) => {
        // Process users data to get feedback statistics
        const ratingCounts = {
          '1': 0, '2': 0, '3': 0, '4': 0, '5': 0
        };
        
        let totalFeedbackUsers = 0;
        let totalRatingSum = 0;

        // Count feedback and ratings from users data
        usersData.users.forEach(user => {
          if (user.feedbackProvided === true) {
            totalFeedbackUsers++;
            if (user.lastRating) {
              ratingCounts[user.lastRating.toString()]++;
              totalRatingSum += user.lastRating;
            }
          }
        });

        const averageRating = totalFeedbackUsers > 0 
          ? (totalRatingSum / totalFeedbackUsers).toFixed(1) 
          : 0;

        const transformedData = {
          totalUsers: analyticsData.overview.totalUsers,
          activeUsers: analyticsData.overview.activeUsers,
          inactiveUsers: analyticsData.overview.inactiveUsers,
          totalMessages: analyticsData.engagement.messageStats.totalMessages,
          averageMessagesPerUser: analyticsData.engagement.messageStats.avgMessagesPerUser,
          maxMessages: analyticsData.engagement.messageStats.maxMessages,
          minMessages: analyticsData.engagement.messageStats.minMessages,
          genderStats: analyticsData.demographics.usersBySex,
          ageRangeStats: analyticsData.demographics.usersByAgeRange,
          districtStats: analyticsData.demographics.usersByDistrict,
          feedbackStats: {
            total: totalFeedbackUsers,
            percentage: ((totalFeedbackUsers / analyticsData.overview.totalUsers) * 100).toFixed(1),
            ratingCounts: ratingCounts,
            averageRating: averageRating
          }
        };
        setAnalyticsData(transformedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching analytics:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">Error: {error}</Typography>
      </Box>
    );
  }

  if (!analyticsData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  // Common chart options with adjusted scales
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12,
            weight: 'bold'
          },
          color: '#333'
        }
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 12,
        titleColor: '#fff',
        titleFont: { size: 14 },
        bodyFont: { size: 13 },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
            weight: 'bold'
          },
          color: '#333',
          maxRotation: 45,
          minRotation: 45
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        }
      },
      y: {
        ticks: {
          font: {
            size: 12,
            weight: 'bold'
          },
          color: '#333'
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.1)'
        }
      }
    }
  };

  // Bar chart specific options (with more space for labels)
  const barOptions = {
    ...options,
    layout: {
      padding: {
        bottom: 30
      }
    }
  };

  // Doughnut specific options
  const doughnutOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins.legend,
        position: 'right'
      }
    }
  };

  // User Statistics
  const userStatsData = {
    labels: ["Total Users", "Active Users", "Inactive Users"],
    datasets: [
      {
        label: "User Statistics",
        data: [
          analyticsData.totalUsers,
          analyticsData.activeUsers,
          analyticsData.inactiveUsers
        ],
        backgroundColor: [
          "rgba(53, 162, 235, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(255, 99, 132, 0.7)",
        ],
        borderColor: [
          "rgba(53, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Message Statistics
  const messageStatsData = {
    labels: ["Total Messages", "Avg/User", "Max Messages", "Min Messages"],
    datasets: [
      {
        label: "Message Statistics",
        data: [
          analyticsData.totalMessages,
          analyticsData.averageMessagesPerUser,
          analyticsData.maxMessages,
          analyticsData.minMessages
        ],
        backgroundColor: [
          "rgba(255, 159, 64, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 99, 132, 0.7)",
        ],
        borderColor: [
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Gender Distribution with fixed labels and distinct colors
  const genderData = {
    labels: analyticsData.genderStats?.map((stat) => 
      stat._id === null ? "Unspecified" : stat._id
    ) || [],
    datasets: [
      {
        data: analyticsData.genderStats?.map((stat) => stat.count) || [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",    // Pink for Female
          "rgba(53, 162, 235, 0.7)",    // Blue for Male
          "rgba(255, 206, 86, 0.7)",    // Yellow for Unspecified
          "rgba(75, 192, 192, 0.7)",    // Teal for Other
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(53, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Age Distribution
  const ageData = {
    labels: analyticsData.ageRangeStats?.map((stat) => 
      stat._id === null ? "Unspecified" : stat._id
    ) || [],
    datasets: [
      {
        label: "Age Distribution",
        data: analyticsData.ageRangeStats?.map((stat) => stat.count) || [],
        backgroundColor: "rgba(153, 102, 255, 0.7)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  // District Distribution (Top 5)
  const districtData = {
    labels: analyticsData.districtStats?.slice(0, 5).map((stat) => 
      stat._id === null ? "Unspecified" : stat._id
    ) || [],
    datasets: [
      {
        label: "Users by District",
        data: analyticsData.districtStats?.slice(0, 5).map((stat) => stat.count) || [],
        backgroundColor: [
          "rgba(53, 162, 235, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(255, 159, 64, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 99, 132, 0.7)",
        ],
        borderColor: [
          "rgba(53, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Feedback Rating Distribution
  const feedbackData = {
    labels: ['5 Stars', '4 Stars', '3 Stars', '2 Stars', '1 Star'],
    datasets: [
      {
        label: 'Number of Ratings',
        data: [
          analyticsData?.feedbackStats?.ratingCounts['5'] || 0,
          analyticsData?.feedbackStats?.ratingCounts['4'] || 0,
          analyticsData?.feedbackStats?.ratingCounts['3'] || 0,
          analyticsData?.feedbackStats?.ratingCounts['2'] || 0,
          analyticsData?.feedbackStats?.ratingCounts['1'] || 0
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.7)',  // 5 stars - Teal
          'rgba(54, 162, 235, 0.7)',  // 4 stars - Blue
          'rgba(255, 206, 86, 0.7)',  // 3 stars - Yellow
          'rgba(255, 159, 64, 0.7)',  // 2 stars - Orange
          'rgba(255, 99, 132, 0.7)',  // 1 star - Red
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" sx={{ mb: 3, color: '#1a237e', fontWeight: 'bold' }}>
        Analytics Dashboard
      </Typography>

      {/* Quick Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Users
              </Typography>
              <Typography variant="h4">
                {analyticsData.totalUsers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Active Users
              </Typography>
              <Typography variant="h4">
                {analyticsData.activeUsers}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {analyticsData.activeRate}% of total
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Messages
              </Typography>
              <Typography variant="h4">
                {analyticsData.totalMessages}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Feedback Received
              </Typography>
              <Typography variant="h4">
                {analyticsData?.feedbackStats?.total || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {analyticsData?.feedbackStats?.percentage}% of users
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Rating
              </Typography>
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
                {analyticsData?.feedbackStats?.averageRating || '0.0'}
                <span style={{ color: '#ffd700', marginLeft: '8px' }}>★</span>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                From {analyticsData?.feedbackStats?.total || 0} ratings
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        {/* User Statistics */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              User Distribution
            </Typography>
            <Bar options={barOptions} data={userStatsData} />
          </Paper>
        </Grid>

        {/* Message Statistics */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              Message Statistics
            </Typography>
            <Bar options={barOptions} data={messageStatsData} />
          </Paper>
        </Grid>

        {/* Gender Distribution */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              Gender Distribution
            </Typography>
            <Doughnut options={doughnutOptions} data={genderData} />
          </Paper>
        </Grid>

        {/* Age Distribution */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              Age Distribution
            </Typography>
            <Bar options={barOptions} data={ageData} />
          </Paper>
        </Grid>

        {/* District Distribution */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              Top 5 Districts
            </Typography>
            <Bar options={barOptions} data={districtData} />
          </Paper>
        </Grid>

        {/* Feedback Ratings */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3, height: 400 }} elevation={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1a237e' }}>
              Rating Distribution
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Bar options={barOptions} data={feedbackData} />
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                  Total Feedback Count: {analyticsData?.feedbackStats?.total || 0}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
