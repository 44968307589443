import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Paper,
  useTheme
} from '@mui/material';
import {
  PeopleOutline,
  PersonOutline,
  ChatBubbleOutline,
  MessageOutlined
} from '@mui/icons-material';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('/api/analytics/dashboard');
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Typography color="error">Error loading dashboard: {error}</Typography>
      </Box>
    );
  }

  const StatCard = ({ title, value, icon, color }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: '50%',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {icon}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      {/* Overview Statistics */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Users"
            value={data.overview.totalUsers}
            icon={<PeopleOutline sx={{ color: 'white' }} />}
            color="#1976d2"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Users"
            value={data.overview.activeUsers}
            icon={<PersonOutline sx={{ color: 'white' }} />}
            color="#2e7d32"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Avg Messages/User"
            value={data.engagement.messageStats.avgMessagesPerUser.toFixed(1)}
            icon={<ChatBubbleOutline sx={{ color: 'white' }} />}
            color="#ed6c02"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Messages"
            value={data.engagement.messageStats.totalMessages}
            icon={<MessageOutlined sx={{ color: 'white' }} />}
            color="#9c27b0"
          />
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        {/* District Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Users by District
            </Typography>
            <Box height={300}>
              <BarChart
                width={500}
                height={300}
                data={data.demographics.usersByDistrict.map(item => ({
                  name: item._id === null ? 'Unspecified' : item._id,
                  users: item.count
                }))}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="name"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis 
                  label={{ value: 'Number of Users', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip formatter={(value) => [`${value} users`, 'Total']} />
                <Legend />
                <Bar 
                  dataKey="users" 
                  fill="#8884d8"
                  name="Users"
                >
                  {data.demographics.usersByDistrict.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </Box>
          </Paper>
        </Grid>

        {/* Gender Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Users by Gender
            </Typography>
            <Box height={300} display="flex" justifyContent="center">
              <PieChart width={400} height={300}>
                <Pie
                  data={data.demographics.usersBySex.map(item => ({
                    name: item._id === null ? 'Unspecified' : item._id,
                    value: item.count
                  }))}
                  cx={200}
                  cy={150}
                  labelLine={true}
                  label={({ name, value }) => `${name}: ${value}`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.demographics.usersBySex.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => [value, 'Users']} />
                <Legend />
              </PieChart>
            </Box>
          </Paper>
        </Grid>

        {/* Age Distribution */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Users by Age Range
            </Typography>
            <Box height={300}>
              <BarChart
                width={1000}
                height={300}
                data={data.demographics.usersByAgeRange.map(item => ({
                  name: item._id === null ? 'Unspecified' : item._id,
                  users: item.count
                }))}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="name"
                  interval={0}
                  angle={0}
                  textAnchor="middle"
                />
                <YAxis 
                  label={{ value: 'Number of Users', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip formatter={(value) => [`${value} users`, 'Total']} />
                <Legend />
                <Bar 
                  dataKey="users" 
                  fill="#82ca9d"
                  name="Users"
                >
                  {data.demographics.usersByAgeRange.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
