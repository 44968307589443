import { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
} from '@mui/material';

export default function AppSettings() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Test Settings Page
      </Typography>
      <Paper sx={{ p: 3 }}>
        <Typography>
          This is a test settings page to verify routing.
        </Typography>
      </Paper>
    </Box>
  );
}
