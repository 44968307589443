import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Search as SearchIcon,
  WhatsApp as WhatsAppIcon,
  Message as MessageIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';

// Chat History Dialog Component
const ChatHistoryDialog = ({ open, handleClose, messages, phoneNumber }) => (
  <Dialog 
    open={open} 
    onClose={handleClose}
    maxWidth="md"
    fullWidth
  >
    <DialogTitle>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Chat History - {phoneNumber}</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent>
      <List>
        {messages.map((message, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={message}
                secondary={new Date().toLocaleString()} // You can add actual timestamp if available
              />
            </ListItem>
            {index < messages.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </DialogContent>
  </Dialog>
);

const ChatTable = ({ data, loading, totalCount, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = (chat) => {
    setSelectedChat(chat);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedChat(null);
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return 'Unknown';
    return phone.replace('whatsapp:', '');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Phone Number</TableCell>
              <TableCell>Message Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((chat) => (
                <TableRow hover key={chat.phoneNumber}>
                  <TableCell>{formatPhoneNumber(chat.phoneNumber)}</TableCell>
                  <TableCell>{Array.isArray(chat.messages) ? chat.messages.length : 0}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => window.open(`https://wa.me/${formatPhoneNumber(chat.phoneNumber).replace('+', '')}`, '_blank')}
                      size="small"
                      title="Open WhatsApp chat"
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenDialog(chat)}
                      size="small"
                      title="View message history"
                      disabled={!Array.isArray(chat.messages) || chat.messages.length === 0}
                    >
                      <MessageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {loading ? (
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                  ) : (
                    'No users found'
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
      {selectedChat && (
        <ChatHistoryDialog
          open={dialogOpen}
          handleClose={handleCloseDialog}
          messages={selectedChat.messages || []}
          phoneNumber={formatPhoneNumber(selectedChat.phoneNumber)}
        />
      )}
    </>
  );
};

export default function Chats() {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/chats/messages', {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchQuery
          }
        });
        
        setData(response.data.chats);
        setTotalCount(response.data.total);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chats:', error);
        setLoading(false);
      }
    };

    fetchChats();
  }, [page, rowsPerPage, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Chat Management
        </Typography>
      </Box>

      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search in messages..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Chat Table */}
      <ChatTable
        data={data}
        loading={loading}
        totalCount={totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
