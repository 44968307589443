import { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Save as SaveIcon,
  Key as KeyIcon,
  Security as SecurityIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';

export default function Settings() {
  const [accountSettings, setAccountSettings] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    email: '',
    twoFactorEnabled: false,
    emailNotifications: true,
    sessionTimeout: '30',
  });

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (field) => (event) => {
    setAccountSettings(prev => ({
      ...prev,
      [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  const handleSave = () => {
    // TODO: Implement actual settings save
    setNotification({
      open: true,
      message: 'Settings saved successfully!',
      severity: 'success',
    });
  };

  const toggleShowPassword = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <SecurityIcon /> Account Settings
      </Typography>

      <Stack spacing={3}>
        {/* Account Security */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <KeyIcon /> Change Password
          </Typography>
          <Stack spacing={3}>
            <TextField
              label="Current Password"
              value={accountSettings.currentPassword}
              onChange={handleChange('currentPassword')}
              fullWidth
              required
              type={showPasswords.currentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleShowPassword('currentPassword')}>
                      {showPasswords.currentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New Password"
              value={accountSettings.newPassword}
              onChange={handleChange('newPassword')}
              fullWidth
              required
              type={showPasswords.newPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleShowPassword('newPassword')}>
                      {showPasswords.newPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm New Password"
              value={accountSettings.confirmPassword}
              onChange={handleChange('confirmPassword')}
              fullWidth
              required
              type={showPasswords.confirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleShowPassword('confirmPassword')}>
                      {showPasswords.confirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Paper>

        {/* Account Information */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccountCircleIcon /> Account Information
          </Typography>
          <Stack spacing={3}>
            <TextField
              label="Email Address"
              value={accountSettings.email}
              onChange={handleChange('email')}
              fullWidth
              required
              type="email"
              placeholder="admin@example.com"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={accountSettings.twoFactorEnabled}
                  onChange={handleChange('twoFactorEnabled')}
                />
              }
              label="Enable Two-Factor Authentication"
            />
          </Stack>
        </Paper>

        {/* Security Preferences */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Security Preferences
          </Typography>
          <Stack spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={accountSettings.emailNotifications}
                  onChange={handleChange('emailNotifications')}
                />
              }
              label="Email notifications for security events"
            />
            <TextField
              label="Session Timeout (minutes)"
              value={accountSettings.sessionTimeout}
              onChange={handleChange('sessionTimeout')}
              type="number"
              InputProps={{
                inputProps: { min: 5, max: 120 }
              }}
              helperText="Session will automatically expire after this period of inactivity"
            />
          </Stack>
        </Paper>

        {/* Save Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            size="large"
          >
            Save Changes
          </Button>
        </Box>
      </Stack>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setNotification(prev => ({ ...prev, open: false }))}
          severity={notification.severity}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
