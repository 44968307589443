import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  Search as SearchIcon,
  Download as DownloadIcon,
  WhatsApp as WhatsAppIcon,
  Message as MessageIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axios from 'axios';
import config from '../config';

const DataStats = ({ title, value, description, loading }) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" component="div">
        {loading ? <CircularProgress size={30} /> : value}
      </Typography>
      {description && (
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const DataTable = ({ data, loading, totalCount, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
  const columns = [
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'district', label: 'District' },
    { id: 'sex', label: 'Gender' },
    { id: 'ageRange', label: 'Age Range' },
    { id: 'joinDate', label: 'Join Date' },
    { id: 'messageCount', label: 'Messages' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' }
  ];

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const getJoinDate = (user) => {
    // Try to get the creation date first
    if (user.createdAt) {
      return formatDate(user.createdAt);
    }
    // If no creation date, try to get first interaction time
    if (user.firstInteractionTime) {
      return formatDate(user.firstInteractionTime);
    }
    // If neither exists, show when the record was last updated
    if (user.updatedAt) {
      return formatDate(user.updatedAt);
    }
    return '-';
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow hover key={row.id}>
                <TableCell>{row.phoneNumber}</TableCell>
                <TableCell>{row.district}</TableCell>
                <TableCell>{row.sex}</TableCell>
                <TableCell>{row.ageRange}</TableCell>
                <TableCell>
                  <Tooltip title={row.createdAt ? "Created At" : row.firstInteractionTime ? "First Interaction" : "Last Updated"}>
                    <span>{getJoinDate(row)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{row.messages?.length || row.messageCount || 0}</TableCell>
                <TableCell>
                  <Chip 
                    label={row.status || (row.lastInteractionTime && new Date(row.lastInteractionTime) >= new Date(Date.now() - 24 * 60 * 60 * 1000) ? 'active' : 'inactive')}
                    color={row.status === 'active' || (row.lastInteractionTime && new Date(row.lastInteractionTime) >= new Date(Date.now() - 24 * 60 * 60 * 1000)) ? 'success' : 'default'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton size="small">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </>
  );
};

export default function Data() {
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/data/stats`);
        setStats(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stats:', error);
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const status = ['all', 'active', 'inactive'][tabValue];
        const response = await axios.get(`${config.apiBaseUrl}/data/users`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchQuery,
            status
          }
        });
        
        setData(response.data.users);
        setTotalCount(response.data.total);
        setTableLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setTableLoading(false);
      }
    };

    fetchData();
  }, [page, rowsPerPage, searchQuery, tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const formatNumber = (num) => {
    if (num >= 1000) {
      return `${(num/1000).toFixed(1)}K`;
    }
    return num.toString();
  };

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">Data Management</Typography>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={() => {/* Implement export functionality */}}
        >
          Export Data
        </Button>
      </Box>

      {/* Stats Overview */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <DataStats
            title="Total Records"
            value={stats ? formatNumber(stats.totalRecords) : '-'}
            description="Across all categories"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataStats
            title="Active Users"
            value={stats ? formatNumber(stats.activeUsers) : '-'}
            description={stats ? `${stats.activeUsersPercentage}% of total` : ''}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataStats
            title="Total Messages"
            value={stats ? formatNumber(stats.totalMessages) : '-'}
            description="All time"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataStats
            title="Average Messages"
            value={stats ? formatNumber(Math.round(stats.totalMessages / stats.totalRecords)) : '-'}
            description="Per user"
            loading={loading}
          />
        </Grid>
      </Grid>

      {/* Search and Filters */}
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by phone number or district..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Data Tabs */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="All Users" />
            <Tab label="Active Users" />
            <Tab label="Inactive Users" />
          </Tabs>
        </Box>

        <DataTable
          data={data}
          loading={tableLoading}
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
