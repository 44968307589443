import { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Chip,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Stack,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import {
  Send as SendIcon,
  Description as FormIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const columns = [
  { id: 'select', label: '', minWidth: 50 },
  { id: 'orderNumber', label: 'No', minWidth: 70, align: 'center' },
  { id: 'phoneNumber', label: 'Phone Number', minWidth: 170 },
  { id: 'ageRange', label: 'Age Range', minWidth: 100 },
  { id: 'sex', label: 'Sex', minWidth: 80 },
  { id: 'district', label: 'District', minWidth: 150 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'feedbackStatus', label: 'Feedback', minWidth: 120 },
  { id: 'lastInteractionTime', label: 'Last Active', minWidth: 170 },
  { id: 'messages', label: 'Total Messages', minWidth: 100 },
];

const AGE_RANGES = [
  '18-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65+'
];

const SEX_OPTIONS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' }
];

const FEEDBACK_STATUS = {
  provided: 'Feedback Provided',
  not_provided: 'No Feedback'
};

const FEEDBACK_STATUSES = [
  { value: 'not_started', label: 'Not Started' },
  { value: 'pending', label: 'Pending' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' }
];

const messageTemplates = [
  {
    id: 1,
    name: 'Welcome Message',
    content: 'Welcome to our service! How can we help you today?',
    fields: {
      ageRange: true,
      sex: true,
      district: true
    }
  },
  {
    id: 2,
    name: 'Update Notification',
    content: 'We have some important updates to share with you.',
    fields: {
      feedbackStatus: true
    }
  },
  {
    id: 3,
    name: 'Feedback Request',
    content: 'We value your feedback. Please take a moment to share your experience.',
    fields: {
      feedbackStatus: true
    }
  },
];

const formTemplates = [
  {
    id: 1,
    name: 'Initial Assessment',
    description: 'Collect initial user information',
    defaultFields: ['ageRange', 'sex', 'district']
  },
  {
    id: 2,
    name: 'Feedback Form',
    description: 'Collect user feedback about our service',
    defaultFields: ['feedbackStatus']
  },
  {
    id: 3,
    name: 'Contact Update Form',
    description: 'Update user contact information',
    defaultFields: ['district']
  },
];

const FORM_FIELDS = [
  { 
    id: 'ageRange', 
    label: 'Age Range', 
    type: 'select',
    icon: <FormIcon />,
    options: AGE_RANGES
  },
  { 
    id: 'sex', 
    label: 'Sex', 
    type: 'select',
    icon: <FormIcon />,
    options: SEX_OPTIONS.map(opt => opt.value)
  },
  { 
    id: 'district', 
    label: 'District', 
    type: 'text',
    icon: <FormIcon />
  },
  { 
    id: 'feedbackStatus', 
    label: 'Feedback Status', 
    type: 'select',
    icon: <FormIcon />,
    options: FEEDBACK_STATUSES.map(status => status.value)
  }
];

const getFeedbackStatusLabel = (status) => {
  return FEEDBACK_STATUS[status] || status;
};

const getFeedbackStatusColor = (status) => {
  switch (status) {
    case 'provided':
      return 'success';
    case 'not_provided':
      return 'warning';
    default:
      return 'default';
  }
};

const getStatusColor = (status, lastInteractionTime) => {
  if (status === 'active') {
    // If no last interaction time, show as active with success color
    if (!lastInteractionTime) return 'success';
    
    // Check how long ago was the last interaction
    const lastInteraction = new Date(lastInteractionTime);
    const now = new Date();
    const daysSinceLastInteraction = (now - lastInteraction) / (1000 * 60 * 60 * 24);
    
    if (daysSinceLastInteraction <= 7) {
      return 'success'; // Very active - green
    } else if (daysSinceLastInteraction <= 30) {
      return 'warning'; // Moderately active - yellow
    } else {
      return 'info'; // Less active but still within 3 months - blue
    }
  }
  return 'error'; // Inactive - red
};

// Format date for display
const formatDate = (dateString) => {
  if (!dateString) return 'New User';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

// Format relative time for tooltip
const formatRelativeTime = (dateString) => {
  if (!dateString) return 'New User';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  
  const now = new Date();
  const diff = now - date;
  
  // If less than 24 hours ago, show relative time
  if (diff < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(diff / (60 * 60 * 1000));
    if (hours < 1) {
      const minutes = Math.floor(diff / (60 * 1000));
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  
  // If less than 7 days ago, show days ago
  if (diff < 7 * 24 * 60 * 60 * 1000) {
    const days = Math.floor(diff / (24 * 60 * 60 * 1000));
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
  
  return formatDate(dateString);
};

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    ageRange: '',
    district: '',
    feedbackStatus: ''
  });
  const [availableFilters, setAvailableFilters] = useState({
    locations: [],
    ageRanges: []
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [messageDialog, setMessageDialog] = useState(false);
  const [formDialog, setFormDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [selectedForm, setSelectedForm] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [createFormDialog, setCreateFormDialog] = useState(false);
  const [newForm, setNewForm] = useState({
    name: '',
    description: '',
    fields: [],
  });
  const [fieldDialog, setFieldDialog] = useState(false);
  const [currentField, setCurrentField] = useState({
    type: '',
    label: '',
    required: false,
    options: [''], // For radio/select fields
  });
  const [editingFieldIndex, setEditingFieldIndex] = useState(-1);
  const [formFieldsDialog, setFormFieldsDialog] = useState(false);
  const [formFields, setFormFields] = useState([]);

  // Fetch users from API
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page,
        limit: rowsPerPage,
        ...filters
      });
      const response = await fetch(`/api/users?${queryParams}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch users');
      }
      
      setUsers(data.users);
      setTotalUsers(data.total);
      setAvailableFilters(data.filters);
    } catch (err) {
      setError(err.message);
      setNotification({
        open: true,
        message: `Error: ${err.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Update user status
  const handleStatusChange = async (userId, newStatus) => {
    try {
      const response = await fetch(`/api/users/${userId}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update user status');
      }
      
      // Refresh users list
      fetchUsers();
      
      setNotification({
        open: true,
        message: 'User status updated successfully',
        severity: 'success'
      });
    } catch (err) {
      setNotification({
        open: true,
        message: `Error: ${err.message}`,
        severity: 'error'
      });
    }
  };

  // Handle filter changes
  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
    setPage(0); // Reset to first page when filter changes
  };

  // Effect to fetch users when page, rowsPerPage or filters change
  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, filters]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map(user => user._id));
    }
  };

  const handleSendMessage = () => {
    // TODO: Implement actual message sending
    setMessageDialog(false);
    setNotification({
      open: true,
      message: 'Message sent successfully!',
      severity: 'success'
    });
    setCustomMessage('');
    setSelectedTemplate('');
  };

  const handleSendForm = () => {
    // TODO: Implement actual form sending
    setFormDialog(false);
    setNotification({
      open: true,
      message: 'Form sent successfully!',
      severity: 'success'
    });
    setSelectedForm('');
  };

  const handleAddField = () => {
    setCurrentField({
      type: '',
      label: '',
      required: false,
      options: [''], // For radio/select fields
    });
    setEditingFieldIndex(-1);
    setFieldDialog(true);
  };

  const handleEditField = (index) => {
    setCurrentField(newForm.fields[index]);
    setEditingFieldIndex(index);
    setFieldDialog(true);
  };

  const handleDeleteField = (index) => {
    setNewForm(prev => ({
      ...prev,
      fields: prev.fields.filter((_, i) => i !== index)
    }));
  };

  const handleSaveField = () => {
    if (editingFieldIndex === -1) {
      setNewForm(prev => ({
        ...prev,
        fields: [...prev.fields, currentField]
      }));
    } else {
      setNewForm(prev => ({
        ...prev,
        fields: prev.fields.map((field, index) =>
          index === editingFieldIndex ? currentField : field
        )
      }));
    }
    setFieldDialog(false);
  };

  const handleAddOption = () => {
    setCurrentField(prev => ({
      ...prev,
      options: [...prev.options, '']
    }));
  };

  const handleOptionChange = (index, value) => {
    setCurrentField(prev => ({
      ...prev,
      options: prev.options.map((opt, i) => i === index ? value : opt)
    }));
  };

  const handleDeleteOption = (index) => {
    setCurrentField(prev => ({
      ...prev,
      options: prev.options.filter((_, i) => i !== index)
    }));
  };

  const handleSaveForm = () => {
    // TODO: Implement actual form saving to backend
    const newFormTemplate = {
      id: formTemplates.length + 1,
      name: newForm.name,
      description: newForm.description,
      fields: newForm.fields,
    };
    
    // For now, just show success message
    setNotification({
      open: true,
      message: 'Form template created successfully!',
      severity: 'success'
    });
    
    setCreateFormDialog(false);
    setNewForm({
      name: '',
      description: '',
      fields: [],
    });
  };

  // Format phone number by removing any WhatsApp prefix
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'No Phone';
    // Remove any "whatsapp:" prefix if it exists
    return phoneNumber.replace(/^whatsapp:/, '');
  };

  const handleCloseFieldDialog = () => {
    setFieldDialog(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">
          Manage Users
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={() => setMessageDialog(true)}
            disabled={selectedUsers.length === 0}
          >
            Send Message
          </Button>
          <Button
            variant="contained"
            startIcon={<FormIcon />}
            onClick={() => setFormDialog(true)}
            disabled={selectedUsers.length === 0}
          >
            Send Form
          </Button>
          <Button
            variant="outlined"
            startIcon={<FormIcon />}
            onClick={() => setCreateFormDialog(true)}
          >
            Create Form
          </Button>
        </Stack>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === 'select' ? (
                    <Checkbox
                      checked={selectedUsers.length === users.length}
                      indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                      onChange={handleSelectAll}
                    />
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No users found
                </TableCell>
              </TableRow>
            ) : (
              users.map((user, index) => {
                const statusColor = getStatusColor(user.status, user.lastInteractionTime);
                const lastActiveText = user.lastInteractionTime ? 
                  `Last active: ${formatRelativeTime(user.lastInteractionTime)}` : 
                  'New User';
                const orderNumber = page * rowsPerPage + index + 1;
                
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={user._id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedUsers.includes(user._id)}
                        onChange={() => handleUserSelect(user._id)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">
                        {orderNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={user.phoneNumber ? 'No order' : 'No phone number'}>
                        <span>{formatPhoneNumber(user.phoneNumber)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{user.ageRange}</TableCell>
                    <TableCell>{user.sex}</TableCell>
                    <TableCell>{user.district}</TableCell>
                    <TableCell>
                      <Tooltip title={lastActiveText}>
                        <Chip
                          label={user.status}
                          color={statusColor}
                          size="small"
                          onClick={() => handleStatusChange(user._id, user.status === 'active' ? 'inactive' : 'active')}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={user.feedbackProvided ? 'Feedback has been provided' : 'No feedback provided yet'}>
                        <Chip
                          label={user.feedbackProvided ? 'Feedback Provided' : 'No Feedback'}
                          color={user.feedbackProvided ? 'success' : 'warning'}
                          size="small"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {user.lastInteractionTime ? (
                        <Tooltip title={formatRelativeTime(user.lastInteractionTime)}>
                          <span>{formatDate(user.lastInteractionTime)}</span>
                        </Tooltip>
                      ) : (
                        'New User'
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={`Total Messages: ${user.messages ? user.messages.length : 0}`}>
                        <span>{user.messages ? user.messages.length : 0}</span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Send Message Dialog */}
      <Dialog 
        open={messageDialog} 
        onClose={() => setMessageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Send Message to Users</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Message Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                  const template = messageTemplates.find(t => t.id === e.target.value);
                  if (template) {
                    setCustomMessage(template.content);
                  }
                }}
                label="Message Template"
              >
                {messageTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Message"
              multiline
              rows={4}
              value={customMessage}
              onChange={(e) => setCustomMessage(e.target.value)}
              fullWidth
            />
            <Typography variant="body2" color="textSecondary">
              Selected Users: {selectedUsers.length}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMessageDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleSendMessage} 
            variant="contained"
            disabled={!customMessage.trim()}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send Form Dialog */}
      <Dialog 
        open={formDialog} 
        onClose={() => setFormDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Send Form to Users</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Select Form</InputLabel>
              <Select
                value={selectedForm}
                onChange={(e) => setSelectedForm(e.target.value)}
                label="Select Form"
              >
                {formTemplates.map((form) => (
                  <MenuItem key={form.id} value={form.id}>
                    {form.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedForm && (
              <Typography variant="body2" color="textSecondary">
                {formTemplates.find(f => f.id === selectedForm)?.description}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary">
              Selected Users: {selectedUsers.length}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFormDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleSendForm} 
            variant="contained"
            disabled={!selectedForm}
          >
            Send Form
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Form Dialog */}
      <Dialog
        open={createFormDialog}
        onClose={() => setCreateFormDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Form Template</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label="Form Name"
              value={newForm.name}
              onChange={(e) => setNewForm(prev => ({ ...prev, name: e.target.value }))}
              fullWidth
              required
            />
            <TextField
              label="Description"
              value={newForm.description}
              onChange={(e) => setNewForm(prev => ({ ...prev, description: e.target.value }))}
              fullWidth
              multiline
              rows={2}
            />
            
            <Box>
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Form Fields</Typography>
                <Button
                  startIcon={<FormIcon />}
                  onClick={handleAddField}
                  variant="outlined"
                  size="small"
                >
                  Add Field
                </Button>
              </Box>
              
              <List>
                {newForm.fields.map((field, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Stack direction="row" spacing={1}>
                        <IconButton edge="end" onClick={() => handleEditField(index)}>
                          <FormIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={() => handleDeleteField(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    }
                  >
                    <ListItemIcon>
                      {FORM_FIELDS.find(type => type.id === field.type)?.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={field.label}
                      secondary={`${field.type === 'custom' ? 'Custom Field' : FORM_FIELDS.find(t => t.id === field.type)?.label}${field.required ? ' (Required)' : ''}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateFormDialog(false)}>Cancel</Button>
          <Button
            onClick={handleSaveForm}
            variant="contained"
            disabled={!newForm.name || newForm.fields.length === 0}
          >
            Save Form Template
          </Button>
        </DialogActions>
      </Dialog>

      {/* Form Fields Dialog */}
      <Dialog 
        open={formFieldsDialog} 
        onClose={() => setFormFieldsDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Form Fields</DialogTitle>
        <DialogContent>
          <List>
            {formFields.map((field, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleDeleteField(index)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <FormIcon />
                </ListItemIcon>
                <ListItemText 
                  primary={field.label}
                  secondary={`Type: ${field.type}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFormFieldsDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Add/Edit Field Dialog */}
      <Dialog
        open={fieldDialog}
        onClose={handleCloseFieldDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{editingFieldIndex === -1 ? 'Add Field' : 'Edit Field'}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Field Type</InputLabel>
              <Select
                value={currentField.type}
                onChange={(e) => {
                  const selectedType = FORM_FIELDS.find(type => type.id === e.target.value);
                  setCurrentField(prev => ({
                    ...prev,
                    type: e.target.value,
                    options: selectedType?.options ? [''] : undefined
                  }));
                }}
                label="Field Type"
              >
                {FORM_FIELDS.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    <ListItemIcon>
                      {type.icon}
                    </ListItemIcon>
                    <ListItemText primary={type.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Field Label"
              value={currentField.label}
              onChange={(e) => setCurrentField(prev => ({ ...prev, label: e.target.value }))}
              fullWidth
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentField.required}
                    onChange={(e) => setCurrentField(prev => ({ ...prev, required: e.target.checked }))}
                  />
                }
                label="Required Field"
              />
            </FormGroup>

            {currentField.options && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Options
                </Typography>
                {currentField.options.map((option, index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                    <TextField
                      value={option}
                      onChange={(e) => handleOptionChange(index, e.target.value)}
                      fullWidth
                      size="small"
                    />
                    <IconButton
                      onClick={() => handleDeleteOption(index)}
                      disabled={currentField.options.length <= 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="outlined"
                  onClick={handleAddOption}
                  startIcon={<FormIcon />}
                  size="small"
                >
                  Add Option
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFieldDialog}>Cancel</Button>
          <Button
            onClick={handleSaveField}
            variant="contained"
            disabled={!currentField.label.trim() || !currentField.type}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
